<template>
    
    <v-content> 
        <v-container fluid>

            
            <div v-if="!legalEntities || !brands">
                <!-- data is loading -->
            </div>
            
            <lets-get-started v-else-if="!legalEntities.length" />
            
            <core-view v-else /> 

        </v-container>

    </v-content>
    


</template>

<script>
import LetsGetStarted from '@/components/LetsGetStarted'
import CoreView from './CoreView'
export default {
    name: 'DashboardCoreLeRequiredView',

    components: {
        'lets-get-started': LetsGetStarted,
        'core-view': CoreView
    },

    computed: {
        brands() {
            return this.$store.getters.brands
        },
        legalEntities() {
            return this.$store.getters.legalEntities
        }
    }
}
</script>
