<template>
    <v-container>

        <v-form @submit.prevent="confirm">

        <h3>{{$t('actions.getstarted.title')}}</h3>

        <v-radio-group v-model="vatnumbermode">
            <v-radio
                :label="$t('actions.getstarted.continueWithVATNumber')"
                value="withvatnumber"
            />

            <v-radio
                :label="$t('actions.getstarted.continueWithoutVATNumber')"
                value="withoutvatnumber"
            />
        </v-radio-group>

        <div v-if="useVATNumber">

            <v-layout row class="ma-0" align-center>

                <v-text-field 
                        :label="$t('labels.vatNumber')"
                        v-model="vat"
                        type="text" />

                    <v-btn color="primary" 
                    :loading="loading && !vat" 
                    :disabled="!vat || loading" 
                    @click="verifyVat">
                        {{$t('labels.check')}}
                    </v-btn>
                

            </v-layout>

            <v-subheader class="ma-0 pa-0">{{$t('actions.getstarted.companyDetails')}}:</v-subheader>
            <v-card class="pa-3 mt-0">
                {{verifiedData.companyName}} <br/><br/>
                {{verifiedData.street}} {{verifiedData.houseNumber}}<br/>
                {{verifiedData.zipCode}} {{verifiedData.city}}
            </v-card>

            <v-checkbox v-model="isVATLiable" :label="$t('actions.getstarted.companyIsVATLiable')" />

        </div>


        <div v-if="!useVATNumber" class="mt-5">
            <!--spacing-->
        </div>

        

       

        <v-text-field class="mt-0 pt-0"
                :label="$t('actions.getstarted.brandName')"
                v-model="brandName"
                :hint="$t('actions.getstarted.brandNameHint')"
                persistent-hint
                type="text" />

        <div v-if="!useVATNumber">
                
            <v-row>

               

                    <v-col
                        cols="12"
                        xs="12"
                        sm="8"
                    >
                  <v-text-field
                    v-model="location.street"
                    :label="$t('labels.location.street')"
                  />

                    </v-col>

                    <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                    >
                  
                  <v-text-field
                    v-model="location.houseNumber"
                    :label="$t('labels.location.housenumber')"
                  />
                    </v-col>

                    <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                    >
                  
                  <v-text-field
                    v-model="location.zipCode"
                    :label="$t('labels.location.postalcode')"
                  />
                    </v-col>

                    <v-col
                        cols="12"
                        xs="12"
                        sm="8"
                    >
                  
                  <v-text-field
                    v-model="location.city"
                    :label="$t('labels.location.city')"
                    
                  />
                    </v-col>

                </v-row>

        </div>
            
             <v-text-field v-model="iban" :label="$t('labels.iban')" 
                        :hint="$t('actions.getstarted.receivingIbanHint')"
                        persistent-hint />

            <v-btn type="submit" color="primary" class="mt-3" :loading="loading" :disabled="confirmDisabled">{{$t('labels.confirm')}}</v-btn>

        </v-form>

    </v-container>
</template>

<script>
export default {

    data() {
        return {
            vatnumbermode: "withvatnumber",
            verifiedData: {},
            location: {
                street: "",
                houseNumber: "",
                zipCode: "",
                city: "",
                country: "BE"
            },
            loading: false,
            iban: '',
            isVATLiable: true,
            vat: '',
             brandName: '',
        }
    },
    computed: {
        confirmDisabled() {
            if (this.loading || !this.iban || !this.brandName)
                return true

            if (this.useVATNumber) 
                return !this.verifiedData.companyName 
            else
                return !this.location.street || !this.location.houseNumber || !this.location.zipCode || !this.location.city
        },
        useVATNumber() {
            return this.vatnumbermode == "withvatnumber"
        }
    },
    methods: {
        verifyVat() {
            this.loading = true
            this.$api.verifyVat(this.vat)
                .then((data) => { this.verifiedData = data })
                .finally(() => { this.loading = false })
        },
        confirm() {

            this.loading = true
            if (!this.useVATNumber) {
                this.isVATLiable = false
                this.vat = ""
            }
            this.$api.addLEComplete(this.vat, this.brandName, this.iban, this.isVATLiable, this.location, 'legal_entity')
                .then(() => { 

                    const brandData = {
                        brandName: this.brandName
                    }

                    if (this.useVATNumber) {
                        brandData.street = this.verifiedData.street,
                        brandData.houseNumber = this.verifiedData.houseNumber,
                        brandData.postalCode = this.verifiedData.zipCode,
                        brandData.city = this.verifiedData.city
                    } else {
                        brandData.street = this.location.street,
                        brandData.houseNumber = this.location.houseNumber,
                        brandData.postalCode = this.location.zipCode,
                        brandData.city = this.location.city
                    }

                    this.$emit('ok') 
                })
                .finally(() => { this.loading = false })
        }
    }
}
</script>