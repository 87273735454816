<template>

    <div>
        <h1>{{title}}</h1>
        <div class="mt-6" v-html="description"></div>

        <v-btn class="mt-6" @click="start" color="primary">{{addBtn}}</v-btn>
        
    </div>

</template>

<script>
import addLegalEntityForm from './addLegalEntityForm'
export default {

    computed: {
        title () {
            return this.$t('letsgetstarted.title'); 
        },

        description() {
            return this.$t('letsgetstarted.description');
        },

        addBtn() {
            return this.$t('letsgetstarted.addBtn');
        }
    },

    methods: {
        start() {
            this.$openModal(addLegalEntityForm,null, this)
                .then(() => { 
                    window.location.reload(); // Easiest option is to refresh all data
                })
        }
    }
    
}
</script>