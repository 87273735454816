<template>

    <div v-if="description" style="margin-bottom: 3em">
        {{description}}
        <v-icon v-if="extraInfo" @click="showExtraInfo">mdi-help-circle-outline</v-icon>
    </div>
    

</template>

<script>
import Message from './Message'
export default {

    computed: {
        description() {
            return this.$t('routes.' + this.$route.name + '.description')
        },
        extraInfo() {
            return this.$t('routes.' + this.$route.name + '.extra-info')
        }
    },
    methods: {
        showExtraInfo() {
            this.$openModal(Message, {message: this.extraInfo}, this)
        }
    }
}
</script>
