<template>



  <!-- show info component about the page we want to enter -->
  <div v-if="infoComponentName && !hasReadInfo">
    <component :is="infoComponentName" @confirm="markRouteAsHasReadInfo" />
  </div>

  <!-- show the page description + actual page if no info or read the info -->
  <div v-else>
      <page-description />
      <router-view />
  </div>

</template>

<script>
import PageDescription from '@/components/PageDescription'
  export default {
    name: 'DashboardCoreView',

    components: {
        'page-description': PageDescription
    },

    data() {
        return {
            hasReadRoutes: []
        }
    },

    computed: {
        infoComponentName() {
          return this.$route.meta.infoComponent;
        },
        hasReadInfo() {
          return this.hasReadRoutes.indexOf(this.$route.name) >= 0
        }

    },

    methods: {
      markRouteAsHasReadInfo() {
        this.hasReadRoutes.push(this.$route.name)
      }
    }
  }
</script>